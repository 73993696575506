.mainPageContainer {
    padding: 16px 15px;

    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }
    }

    .createContainer {
        display: flex;
        flex-direction: column;

        >:not(:last-child) {
            margin-bottom: 24px;
        }

        .createContainer_item {
            display: flex;
            flex-direction: column;

            .createContainer_item_title {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 16px;
                color: var(--white);

                margin-bottom: 16px;
            }

            .createContainer_item_buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                flex-wrap: nowrap;
                column-gap: 16px;
            }

            .createContainer_item_photo_input {
                display: flex;
                align-items: center;

                cursor: pointer;

                img {
                    width: 24px;
                    height: 24px;

                    margin-right: 10px;
                }

                &.error {
                    span {
                        color: #e84266;
                    }
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 15px;
                    color: var(--predict-add-photo-font-color);

                    margin-right: 4px;
                }

            }
        }
    }

    .notification {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 134%;
        text-align: center;
        color: var(--white-color);

        background: var(--notification-card-background-color);
        border-radius: 16px;
        padding: 16px;
        box-sizing: border-box;

        margin-top: 16px;


        img {
            width: 12px;
            height: 12px;
            display: inline;
            margin-left: 2px;
        }
    }

    .confirmButton {
        cursor: pointer;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 13px 54px;

        box-sizing: border-box;

        color: var(--reverse-main-font-color);

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--action-component-active-background-light-color);
    }
}

.button_container {
    display: flex;
    border-radius: 8px;
    padding: 10px 23px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .title {
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;

        font-weight: 700;
        font-size: 18px;
        line-height: 139%;
        text-align: center;

        font-family: var(--font-family);

        margin-right: 4px;
    }

    .title.red {
        color: #f54570;
    }

    .title.green {
        color: var(--success-button-font-color);
    }

    .subTitle {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 14px;
        line-height: 139%;
        text-align: right;

        margin-right: 8px;

    }

    .subTitle.red {
        color: #f54570;
    }

    .subTitle.green {
        color: var(--success-button-font-color);
    }
}

.button_container.error {
    outline: 0.5px solid #e84266;
}

.button_container.red {
    background: rgba(224, 59, 69, 0.09);
}

.button_container.green {
    background-color: var(--success-button-background);
}