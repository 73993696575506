:root {
  --main-green-color: #bbf245;
  --improvement-border-bottom-color: #bae567;
}

.golden-pixie-coin-event {
  --main-green-color: goldenrod;
  --improvement-border-bottom-color: goldenrod;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: "Pixel";
  src: url("Resources/fonts/Pixel Emulator.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: #101615;
  background-image: url("Resources/images/clouds.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 100px;
  font-family: "Inter", sans-serif;
}

/*///////////////////////////////////////////////////////////////*/
.golden-pixie-coin-event {
  background: #000;
}

.golden-pixie-coin-event .game-container_header {
  background: #000;
}

.golden-pixie-coin-event span,
.golden-pixie-coin-event p,
.golden-pixie-coin-event h1,
.golden-pixie-coin-event h2,
.golden-pixie-coin-event .energy_container,
.golden-pixie-coin-event .boost_button,
.golden-pixie-coin-event a {
  text-shadow: 9px -5px 10px goldenrod;
}

.golden-pixie-coin-event .coins_container-row-item {
  box-shadow: 0px 0px 10px -5px goldenrod;
}

/*///////////////////////////////////////////////////////////////*/
.percents_for_referals_text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.app {
  width: 100vw;
  /*min-height: 100vh;*/
  overflow-x: hidden;

  padding-bottom: 100px;

  /*display: flex;*/
  /*justify-content: space-around;*/
  /*align-items: center;*/
}

.App {
  animation: 0.1s show-from-left ease;
}

.game-container {
  padding: 12px;
  box-sizing: border-box;
  animation: 0.1s show-from-top ease;
}

.game-container_header {
  padding: 12px;
  box-sizing: border-box;
  padding-bottom: 10px;
  background-color: #000;
  background-image: url("Resources/images/header-background2.png");
  background-repeat: no-repeat;
  background-position: center -5px;
  background-size: cover;

  animation: 0.1s show-from-top ease;
}


.game-container_header-leftSide {
  display: flex;
  align-items: center;
}



.so-more-referrals-count {
  color: #fff;
  display: block;
  margin-top: -10px;
  margin-bottom: 30px;
  font-size: 12px;
}

.referrals-get-coins-more {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: "Pixel";
}

.referrals-get-coins-more>img {
  width: 20px;
  height: 20px;
}

.leaderboard-header-image {
  display: block;
  margin: 0 auto;
  height: 106px;
}

.game-container_header-leftSide>img {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  margin-right: 8px;
}

.clicker {
  width: 70vw;
  height: 70vw;
  margin: 0 auto;
  outline: none;
  border: none;
  border-radius: 50%;
  background-image: url("Resources/images/clicker-background-default.svg");
  background-position: center;
  background-size: 102%;
  box-shadow: 0px 0px 90px 0px #AD5519;
  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  font-size: 16px;
  color: #333;
}

.clicker.pixie-golden-event-coin::after {
  display: none;
}

.mainpage.coin-timer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  color: #f1f1f1;
}

.clicker.disabled {
  opacity: 0.4;
}

.clicker::after {
  content: "";
  width: 110px;
  height: 210px;
  display: block;
  background-image: url("Resources/images/human-free.png");
  background-repeat: no-repeat;
  background-size: contain;
}

/*.clicker:hover{*/
/*  box-shadow: inset 0px 0px 24px 0px #000;*/
/*}*/
.score {
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 38px;
  justify-content: center;
  margin-top: 16px;

  font-family: "Pixel";

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.score>img {
  /*width: 33px;*/
  /*height: 34px;*/
  margin-top: 10px;
  margin-right: 10px;
}




.game-container_stats {
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  /*padding: 0 15px;*/
}

.game-container_stats-item {
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  margin-right: 10px;

  padding: 3px 10px;

  text-align: center;
  color: #fff;

  border-radius: 2px;
  width: 100%;

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}





.game-container_stats>.game-container_stats-item:last-child {
  margin-right: 0;
}


.game-container_stats-item-name {
  font-size: 12px;
  display: block;
  text-align: center;
}


.game-container_stats-item-value {
  margin-top: 5px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Pixel";

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}


.game-container_stats-item-value>img {
  display: block;
  width: 15px;
  height: 15px;

  margin-right: 4px;
}

.game-container_header-leftSide-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: -0.03em;
  text-align: right;
  color: #fff;
}



.bottom_menu {
  position: fixed;
  z-index: 9999;
  bottom: 14px;
  left: 0;
  width: 100vw;
  height: 69px;

  display: flex;
  justify-content: space-around;

  animation: 0.1s show-from-bottom ease;

}

.bottom_menu-container {
  padding: 0 10px;
  background-image: url("Resources/images/menu-background.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 69px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;

  max-width: 365px;
}




.bottom_menu-item {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bottom_menu-item>img {
  opacity: .3;
}

/* .bottom_menu-item:last-child>img {
    width: 30px;
  } */

.bottom_menu-item.active>img {
  opacity: 1;
}

.leaderboard-container {
  padding: 12px;
  box-sizing: border-box;
  background: #101615;
  padding-bottom: 100px;
}


.leaderboard-list_item {
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border-radius: 2px;
  padding: 14px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 16px;
}



.leaderboard-list_item.current {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.52);
  background: #12221c;
  border: 0.50px solid var(--main-green-color);
}



.leaderboard-list_item-avatar {
  display: block;
  height: 100%;
}

.leaderboard-list_item-avatar-container {
  display: flex;
  justify-content: center;
  height: 43px;
  width: 43px;

}

.leaderboard-list_item-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 16px;
}

.leaderboard-list_item-rating {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(229, 231, 227, 0.17);
  font-size: 16px;
}

.leaderboard-list_item-info-balance-coin {
  width: 15px;
  height: 15px;
  display: block;
  margin-right: 5px;
}

.leaderboard-list_item-info-balance {
  display: flex;
  align-items: center;
  font-family: "Pixel";
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  text-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  margin-top: 8px;
}



.leaderboard-list_item-info-name {
  font-size: 13px;
}

.referrals_container {
  padding: 14px;
  box-sizing: border-box;

  padding-bottom: 50px;
}

.referrals-counter-all {
  font-size: 12px;
}

.referrals_get_coins {
  outline: none;
  border: none;

  width: 100%;
  padding: 18px 0;
  display: block;
  cursor: pointer;
  background-color: var(--main-green-color);
  color: #101615;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  font-family: "Pixel";
}



.referrals_task {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: unset;
  outline: none;
  border: none;
  color: #fff;
  border: 1px solid var(--main-green-color);
  background-color: #0b0f0e;
  text-decoration: none;
}


.referrals_task-text-name {
  font-size: 16px;
  margin-bottom: 5px;
}





.referrals_task>img {
  display: block;
  height: 60px;
}

.referrals_task-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  font-size: 12px;
}

.referrals_container-list-items-item {
  display: flex;
  align-items: center;
}

.referrals_container-list-items-item-info {
  display: block;
}

.referrals_container-list-items-item-info-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}



.referrals_container-list-items-item-info-coins {
  font-family: "Pixel";
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;

  display: flex;
  align-items: center;
  font-size: 16px;
}


.referrals_container-list-items-item-info-coins-image {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.referrals_container-list-items-item-image {
  display: block;
  height: 100%;
}

.referrals_container-list-items-item-image-container {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
}

.referrals_horizontal_line {
  display: block;
  margin: 10px auto;
}

.popup_tasks_buttons-button.disabled {
  background: #646464;
}

.tap-value-with-coin-image {
  display: flex;
  align-items: center;
}

.tap-value-with-coin-image>img {
  margin-right: 5px;
}

.referrals_container-list-levelname {
  color: #fff;
  /*font-family: "Pixel";*/
  display: block;
  font-size: 14px;
  margin-bottom: 15px;
}

.referrals_container-name {
  display: block;
  color: #fff;
  text-transform: uppercase;

  margin-top: 20px;
  font-size: 22px;
}

.reload-page-button {
  outline: none;
  border: none;
  margin-top: 15px;
  width: 80%;
  padding: 15px 0;
  background-color: var(--main-green-color);
  color: #101615;
  font-weight: 600;
  text-transform: uppercase;
}

.referrals_share_container {
  position: fixed;
  bottom: 90px;
  left: 0;
  width: 100vw;

}

.referrals_container_share {
  width: 80%;
  margin: 0 auto;
  height: 69px;
  background-image: url("Resources/images/menu-background.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: #451b0b;
  box-sizing: border-box;
  text-decoration: none;

  border-radius: 10px;
  text-align: center;

  display: flex;
  justify-content: space-around;
  align-items: center;

  /*box-shadow: 0px 0px 7px -3px #fff;*/

  font-family: "Pixel";
}



.referrals_empty {
  display: block;
  color: #fff;
}

.leaderboard-container_name {
  display: block;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 10px;
}

.families-name-page {
  display: block;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 10px;
}


.improve_container-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
  margin: 20px 0;
}

.improve_container-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 25px;
}

.improve_container-row-item {
  color: #fff;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 4%;

  box-shadow: 0px 0px 5px -4px #f1f1f1;
  border-bottom: 1px solid var(--improvement-border-bottom-color);
}



.improve_container-row-item.disabled {
  border-bottom: 1px solid rgba(255, 105, 105, 0.62);
}

/*.improve_container-row-item::after{*/
/*  content: "";*/
/*  display: block;*/
/*  height: 2px;*/
/*  width: 100%;*/
/*  border-radius: 0px 0px 2px 2px;*/
/*  background: #bae567;*/
/*  margin-top: -2px;*/
/*}*/
.coins_container-row-item {
  color: #fff;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 4%;

  box-shadow: 0px 0px 5px -4px #f1f1f1;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}



.coins_container-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90vw;
  margin: 0 auto;
}

.coins_container-row-item>img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.coins_container-row-item-name {
  text-align: center;
  margin-top: 20px;
}

.coins_container-row-item-price {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Pixel";

  margin: 0 auto;
  margin-top: 10px;
}

.coins_container-row-item-price>img {
  margin-right: 5px;
  width: 20px;
}



.improve_container-row-item.disabled img {
  opacity: .3;
}

.improve_container-row-item-main {
  display: flex;
  padding: 12px;
  box-sizing: border-box;
}

.improve_container-row-item-main-leftSide {
  width: 20%;
  padding-right: 10px;
}

.improve_container-row-item-main-leftSide>img {
  /*width: 24px;*/
  /*height: 24px;*/
  display: block;
  width: 100%;
}

.improve_container-row-item-main-rightSide {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.improve_container-row-item-main-rightSide-name {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #fff;
  margin-bottom: 10px;
}

.improve_container-row-item-main-rightSide-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.58);
  margin-bottom: 2px;
}

.improve_container-row-item-main-rightSide-coins {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #fff;
  display: flex;
  align-items: center;
}

.improve_container-row-item-main-rightSide-coins>img {
  margin-right: 5px;
  display: block;
  width: 15px;
  height: 15px;
}

.improve_container-row-item-bottom {
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.improve_container-row-item-bottom-currentLevel {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #fff;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.improve_container-row-item-bottom-price-text {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.improve_container-row-item-bottom-price-text>img {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.improve_container-row-item.disabled,
.boosts_container-row-button.disabled {
  background: #111414;
  color: #666;
  box-shadow: none;
}



.boosts_container-row-button.disabled img {
  opacity: .3;
}

.improve_container-row-item.disabled span,
.boosts_container-row-button.disabled span,
.boosts_container-row-button.disabled span::before {
  background: #111414;
  color: #666;
}


.energy_container {
  display: flex;
  align-items: center;
  font-family: "Pixel";
  color: #fff;

  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.energy_container>img {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 2px;
}



.boost_button {
  font-family: "Pixel";

  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;

  display: flex;
  align-items: center;

  text-decoration: none;

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.boost_button>img {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 2px;
}

.energy_and_boost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;
}

.boosts_container-column {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.boosts_container-row-button {
  outline: none;
  border: none;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  margin-bottom: 16px;
  border-radius: 2px;

  display: flex;

  padding: 12px;
  box-sizing: border-box;
  color: #fff;

  align-items: center;
}



.boosts_container-row-button-icon {
  display: block;
  width: 42px;
  height: 42px;
}

.boosts_container-row-button-text {
  margin-left: 16px;
}

.boosts_container-row-button-text-name {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.03em;
  color: #fff;

  text-align: left;
}



.boosts_container,
.tasks_container {
  padding: 14px;
  box-sizing: border-box;
}



.tasks_container-column {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  overflow-x: scroll;
  grid-auto-flow: column;
}

.boosts_container-name,
.tasks_container-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: -0.03em;
  color: #fff;
}




.boosts_container-row-button-text-undername {
  margin-top: 8px;

  display: flex;
  align-items: flex-end;
}

.popup_tasks_buttons {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.offline_profit_container-content-button-close {
  position: absolute;
  z-index: 100005;
  right: 10px;
  margin-top: -340px;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background: unset;
  color: #fff;
  font-size: 14px;
  background: #333;
  border-radius: 50%;
  padding: 0;
  line-height: 14px;
}

.popup_tasks_buttons-button {
  display: block;
  outline: none;
  border: none;
  width: 100%;

  background: var(--main-green-color);
  padding: 15px 0;
  box-sizing: border-box;

  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #101615;

  border-radius: 2px;

  text-decoration: none;
  text-align: center;
}


.popup_tasks_buttons-bottom {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.popup_tasks_buttons-bottom-text {
  color: #fff;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.popup_tasks_buttons-button.second {
  margin-left: 10px;
}

.offline_profit_container-content.tasks {
  justify-content: space-around;
}

.offline_profit_container-content.tasks>.offline_profit_container-content-text {
  margin-top: 20px;
}

.boosts_container-row-button-text-undername-coins {
  display: flex;
  align-items: center;

  font-family: "Pixel";
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;
}



.boosts_container-row-button-text-undername-coins>img {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.boosts_container-row-button-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.boosts_container-row-button-text-undername-lvl {
  margin-left: 6px;
  display: flex;
  align-items: center;
}


.boosts_container-row-button-text-undername-lvl::before {
  content: "";
  margin-right: 6px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  display: block;
}

.platform-name {
  color: #bdbdbd;
  margin-top: 20px;
}

.admin-text {
  color: #fff;
}

.level-line-container {
  display: flex;
  height: 10px;
  border: 2px solid #1d2222;
  background: #1d2222;
  margin: 0 auto;
  border-radius: 6px;

  margin-bottom: 30px;
}



.level-content {
  width: 90%;
  margin: 0 auto;
}

.level-line {
  width: 20%;
  background-color: var(--main-green-color);
  border-radius: 6px;
}



.level-content-value {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 183%;
  letter-spacing: -0.03em;
  text-align: right;
  box-sizing: border-box;
  padding-left: 5px;
  margin-bottom: 5px;

  user-select: none;
  /* Prevents text selection in most modern browsers */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}


.reloadPage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.reloadPage-container_text {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.offline_profit_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1000000;
}

.offline_profit_container-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 22, 21, 0.57);
  backdrop-filter: blur(5px);

  z-index: 100000;
}

.offline_profit_container-content {
  border-top: 2px solid var(--main-green-color);
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 390px;

  background-color: #101615;
  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 100001;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.offline_profit_container-content-button {
  outline: none;
  border: none;

  display: block;
  margin-top: 30px;
  width: 90%;
  background: var(--main-green-color);
  border-radius: 2px;
  font-weight: bold;
  padding: 15px 0;
  color: #101615;
}


.offline_profit_container-content-text {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.offline_profit_container-content-value {
  display: block;
  margin-top: 10px;
  font-size: 32px;
  color: #fff;

  font-family: "Pixel";

  display: flex;
  align-items: center;

}

.offline_profit_container-content-value>img {
  margin-right: 10px;
}



.tasks_container-item {
  border-radius: 2px;
  outline: none;
  border: none;
  display: flex;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  margin-bottom: 10px;

  width: 80vw;
  margin-right: 10px;
}




.margin-top-20 {
  margin-top: 10px;
}

.tasks_container-item.disabled {
  opacity: .3;
}

.tasks_container-item-text {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 17px;
  justify-content: space-between;
  height: 100%;

  width: 100%;
}

.tasks_container-item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tasks_container-item-text-name {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.03em;
  color: #fff;
  text-align: left;
}


.tasks_container-item-text-value {
  font-family: "Pixel";
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;

  margin-top: 8px;
}



.tasks_container-item-image {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 6px;
}

.bottom_menu-item.tasks>img {
  animation: pulse1 1.5s linear infinite;
}

.bottom_menu-item.tasks.active>img {
  animation: unset;
}

.improvements_header-buttons {
  display: flex;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;

  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.improvements_header-buttons-button {
  display: block;
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  color: #f1f1f1;
  background: #101615;
  border: 1px solid var(--main-green-color);
  /*margin: 0 5px;*/
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
}



.improvements_header-buttons>.improvements_header-buttons-button:first-child {
  width: 100%;
}

.improvements_header-buttons>.improvements_header-buttons-button:nth-child(2),
.improvements_header-buttons>.improvements_header-buttons-button:nth-child(3) {
  width: 47%;
  margin-top: 10px;
}

.improvements_header-buttons-button.active {
  background-color: var(--main-green-color);
  color: #333;
}



.offline_profit_container-content.coins {
  height: 500px;
}

.offline_profit_container.coins>.offline_profit_container-content>img {
  display: block;
  max-width: 160px;
}

.coins_container-row-item.buyed {
  background: unset;
}



.coins-button-choose {
  border: 1px solid var(--main-green-color);
  background: unset;
  padding: 2px 15px;
  color: #f1f1f1;
  border-radius: 2px;
}

.leaderboard-list_item.fixed.current .leaderboard-list_item-rating {
  border-radius: 5px;
  max-width: unset;
  min-width: unset;
  padding: 0 5px;
  width: fit-content;
}

.leaderboard-list_item.fixed.current {
  position: fixed;
  bottom: 100px;
  width: calc(100% - 24px);
  left: 0;
  margin-left: 12px;
}

.coins_container-row-item-boosts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.coins_container-row-item-boosts-item {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.coins_container-row-item-boosts-item>img {
  width: 15px;
  margin-right: 5px;
}

.coins_container-row-item-boosts-item:nth-child(2)>img {
  width: 25px;
  margin-right: -5px;
}

.coins_container-row-item-boosts-popup {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.coins_container-row-item-boosts-popup>.coins_container-row-item-boosts-item {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

.coins_container-row-item-boosts-popup>.coins_container-row-item-boosts-item:nth-child(2) {
  margin-left: -5px;

}

.coins_container-row-item-boosts-popup>.coins_container-row-item-boosts-item:nth-child(2)>img {
  margin-right: 0;
}

.tasks-invite-friends-progress-bar-container {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
}

.tasks-invite-friends-progress-bar {
  width: 100%;
  background: #1D2222;
  border-radius: 10px;
  padding: 4px;
  box-sizing: border-box;
  height: 18px;
  margin-top: 10px;
}


.tasks-invite-friends-progress-bar-value {
  /*width: 44%;*/
  background: var(--main-green-color);
  border-radius: 10px;
  height: 10px;
}


.level-content-level {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.level-content-leaderboard {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  background: linear-gradient(138deg, #ff4a4a 0%, #cf2626 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 4px 8px;
  box-sizing: border-box;
}

.families_list {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  color: #fff;
}

.families_list-item {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border-radius: 2px;
  padding: 10px 15px;
  box-sizing: border-box;
  align-items: center;
}


.families_list-item.selected {
  border: 0.70px solid var(--main-green-color);
}


.family-popup-content-users-item.selected {
  border: 0.70px solid var(--main-green-color);
}



.families_list-item-content {
  margin-left: 10px;
  width: 100%;
}

.families_list-item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.families_list-item-name {
  text-shadow: 0 4px 7px 0 rgba(255, 255, 255, 0.33);
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #fff;
}

.families_list-item-level {
  font-size: 12px;
}

.families_list-item-drop-coins-score {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.families_list-item-drop-coins-score>img {
  display: block;
  margin-right: 5px;
  width: 15px;
  height: 15px;
}

.families_list-item-creator {
  font-size: 12px;
  opacity: 0.6;
}

.families_list-item-counter-users {
  margin-left: 15px;
  font-size: 12px;
  opacity: 0.6;
}

.family-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99800;
}

.family-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(10px);
  background: rgba(16, 22, 21, 0.57);
  z-index: 99900;
}

.family-popup-content {
  background: #101615;
  border-top: 2px solid var(--main-green-color);
  border-radius: 15px 15px 0 0;
  padding: 30px;
  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /*height: 90%;*/
  height: 92vh;

  z-index: 100000;

  animation: 0.3s show-from-bottom ease;

  padding-bottom: 120px;
  box-sizing: border-box;
}



.family-popup-content-name {
  color: #fff;
  width: 100%;
  font-size: 28px;
  width: fit-content;
}

.family-popup-content-users-item {
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px 10px 5px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  margin-bottom: 10px;
}



.family-popup-content-users-item-avatar-container {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.family-popup-content-users-item-avatar {
  height: 100%;
  margin-right: 15px;
}

.family-popup-content-users-item-button-close {
  position: fixed;
  right: 10px;
  margin-top: -20px;

  outline: none;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  background: unset;
  color: #fff;
  font-size: 14px;
  background: #333;
  padding: 0;
  line-height: 14px;
}

.family-popup-content-users-item-button-edit {
  position: fixed;
  right: 45px;
  margin-top: -20px;

  outline: none;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  background: unset;
  color: #fff;
  font-size: 14px;
  background: #333;
  padding: 0;
  line-height: 14px;
}

.family-popup-content-users-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  max-height: 75%;
}

.family-popup-content-description {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  background: #202827;
  border-left: 4px solid #333;
  padding: 5px 5px 5px 10px;
  box-sizing: border-box;

  margin-top: 10px;
}



.family-popup-content-header {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family-popup-content-header-level {
  font-size: 16px;
  color: #fff;
  margin-left: 15px;
}

.family-popup-content-users-count {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

.family-popup-content-users-item-content-totalearn {
  display: flex;
  align-items: center;
}

.family-popup-content-users-item-content-totalearn>img {
  display: block;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.family-popup-content-users-item-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.family-create {
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 50%;
  display: block;
  position: fixed;
  bottom: 100px;
  right: 20px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border: 1px solid #333;
  color: var(--main-green-color);
  font-size: 24px;
  font-weight: 400;
}

.family-popup-content-form-group {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-bottom: 20px;
}

.family-popup-content-name {
  margin-bottom: 10px;
}

.family-popup-content-create {
  outline: none;
  border: none;

  background: var(--main-green-color);
  border-radius: 2px;

  width: 100%;

  padding: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #101615;
}



.family-popup-content-create.disabled {
  background: #949494;
}



.families_list-item-image {
  width: 40px;
  height: 40px;
  display: block;

}

.family-popup-content-form-group>input {
  outline: none;
  border: none;

  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 2px;
  background: rgba(170, 167, 178, 0.09);

  margin-top: 5px;
}

.families-header {
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}


.coin-timer {
  font-family: "Pixel";
}

.families-header.timer {
  color: #f1f1f1;
  text-align: center;
  font-size: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.families-create {
  font-size: 12px;
  padding: 8px 10px;
  outline: none;
  border: none;
  background: var(--main-green-color);
  color: #333;
  border-radius: 2px;
  text-transform: uppercase;
}


.families-create.disabled {
  background: #919191;
}

.create-family-price {
  margin: 0 auto;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #fff;
  font-family: "Pixel";

  margin-bottom: 20px;
}


.create-family-price>img {
  display: block;
  margin-right: 10px;
}

.family-buttons-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.family-buttons-row-button {
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 5px;

  border-radius: 20px;
}

.family-buttons-row-button>svg {
  margin-right: 5px;
  height: 20px;
}

.family-buttons-row-button.donate {
  background: var(--main-green-color);
  background: rgba(255, 255, 255, 0.4);
}

.family-buttons-row-button.leave {
  background: #eb5151;
}

.family-buttons-row-button.join {
  background: var(--main-green-color);
  width: 100%;
}



.error-file-size {
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: center;
  color: #cb0000;
  font-size: 14px;
  font-weight: bold;
}

.families_coins-game {
  box-sizing: border-box;
  padding: 0 20px;
}

.families_coins-game>button {
  width: 100%;

  outline: none;
  border: none;

  background: var(--main-green-color);
  border-radius: 2px;
  color: #101615;
  box-sizing: border-box;

  padding: 5px 20px;

  font-family: "Pixel";
  margin-bottom: 20px;
  font-size: 18px;
}

.family-popup-content-scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.family-popup-content-text-container {
  margin-top: 20px;

}

.family-popup-content-text-container p {
  font-size: 16px;
  color: #fff;

  margin-bottom: 10px;
}

.family-popup-content-play-container {
  position: relative;
  margin-bottom: 20px;
}

.family-popup-content-play-button {
  width: 100%;
  margin-top: 30px;

  outline: none;
  border: none;

  background: var(--main-green-color);
  border-radius: 2px;
  box-sizing: border-box;
  color: #101615;

  padding: 5px 20px;

  font-family: "Pixel";
  font-size: 18px;
}

.family-popup-content-play-button:disabled {
  background: gray;
  color: lightgray;
  cursor: not-allowed;
  opacity: 0.6;
}

.family-popup-content-play-time {
  margin-top: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  font-family: "Pixel";
  color: gray;
  font-size: 12px;
}

.family-popup-content-coin-image {
  margin-top: 20px;
  height: 130px;
}

.family-popup-content-game-results {
  margin-top: 10px;
}

.family-popup-content-game-results>p {
  font-family: "Pixel";
  font-size: 16px;
  color: #fff;

}

.family-popup-content-game-results-list {
  margin-top: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  max-height: 250px;
}

.family-popup-content-game-results-list-item {
  justify-content: space-between;

  display: flex;
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border-radius: 2px;
  padding: 10px 15px;
  box-sizing: border-box;
  align-items: center;

}

.family-popup-content-game-results-list-item_amount,
.family-popup-content-game-results-list-item_date {
  font-size: 14px;
  color: #fff;

  display: flex;
  align-items: center;
}

.family-popup-content-game-results-list-item_amount>img {
  width: 20px;
  height: 20px;

  margin-right: 8px;
}

.family-popup-content-coin-image>img {
  width: 100%;
  height: 100%;
  transform: rotate(-30deg);
}

.families_list-search-form {
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  margin-bottom: 10px;
}



.families_list-search-form>input {
  outline: none;
  border: none;

  background: rgba(170, 167, 178, 0.09);
  border-radius: 2px;
  padding: 10px 13px;

  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #fff;

  width: 100%;
}



.families_list-search-form>button {
  outline: none;
  border: none;

  background: var(--main-green-color);
  border-radius: 2px;
  box-sizing: border-box;

  padding: 5px 20px;

  font-family: "Pixel";
}



.family-popup-content-form-group.edit-family {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.family-popup-content-form-group.edit-family>label {
  display: block;
  min-width: 50px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.family-popup-content-form-group.edit-family>label>img {
  display: block;
  width: 100%;
  height: 100%;
}

.family-popup-content-form-group.edit-family>input {
  display: block;
  margin-top: 0;
  width: 100%;
}

.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
  }

  100% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
}


@keyframes pulse1 {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.15);
  }

  50% {
    transform: scale(1.3);
  }

  75% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}



.just-webapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.just-webapp-text {
  color: #fff;
}

.just-webapp-link {
  color: #f1f1f1;
  margin-top: 20px;
  font-size: 20px;
}

.families_events_award {
  margin: 0 20px;
  background: linear-gradient(180deg, rgba(170, 167, 178, 0.09) 0%, rgba(73, 72, 76, 0.09) 100%);
  border: 0.70px solid var(--main-green-color);
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.families_events_award-value {
  display: flex;
  align-items: center;
  color: #f1f1f1;
  font-family: "Pixel";
  font-size: 24px;
}

.families_events_award-value>img {
  margin-right: 10px;
}

.families_events_award-claim {
  font-size: 24px;
  color: #f1f1f1;
  font-family: "Pixel";
}

.award_event_name {
  display: block;
  margin: 0 20px;
  color: #f1f1f1;
  margin-bottom: 5px;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}

.codes-form-button {
  outline: none;
  border: none;

  background: var(--main-green-color);
  border-radius: 2px;
  padding: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: right;
  color: #101615;
  box-sizing: border-box;
  display: block;
}



.codes-form-input {
  display: block;
  outline: none;
  border: none;

  border-radius: 2px;
  padding: 13px;
  box-sizing: border-box;
  background: rgba(170, 167, 178, 0.09);
  width: 100%;

  color: #fff;
}

.codes-form {
  display: flex;
}



.under-codes-text {
  margin-bottom: 10px;
  color: #f1f1f1;
  display: block;
  font-size: 12px;
  margin-top: 4px;
}



.under-codes-text.error {
  color: red;
}

.under-codes-text.success {
  color: green;
}













@keyframes show-from-left {
  from {
    transform: translateX(-400px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes show-from-top {
  from {
    transform: translateY(-400px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes show-from-bottom {
  from {
    transform: translateY(400px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fly {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}