.glide__slides {
    display: flex;
    justify-content: center;
    /* Центрирование всех слайдов */
    align-items: center;
}

@media (min-width: 1018px) {
    .glide__slides {
        height: 100%;
        align-items: stretch;
    }
    .glide__slide {
        height: 100%;
        height: inherit;
    }
    .glide  {
        height: 100%;
     }
    .glide__track {
       height: 100%;
    }
    
}

.glide__slide {
    flex-shrink: 0;
    /* Предотвращает уменьшение слайдов */
    margin-left: auto;
    margin-right: auto;
}

.slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.glider-track {
    margin: auto;
}
