@mixin blue-gradient-button {
    border-radius: 20px;
    padding: 13px 54px;

    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
    background: var(--action-component-active-background-light-color);

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 132%;
    text-align: center;
    color: var(--card-secondary-bold-color);

    cursor: pointer;
}

.divider {
    width: 1px;
    height: 20px;

    background: var(--divider-background);
}

.profileContainer {
    // padding: 53px 15px;
    padding: 16px;

    .profileContainer_avatarBlock {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-start;

        .profileContainer_avatarBlock_avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            height: 50px;

            .profileContainer_avatarBlock_avatarImage {
                position: absolute;
                top: -40px;

                img {
                    width: 90px;
                    height: 90px;
                }

                .profileContainer_avatarBlock_avatarEditIcon {
                    position: absolute;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;

                    bottom: 10px;
                    right: -5px;

                }
            }



        }

        .profileContainer_avatarBlockLevelContainer {
            display: flex;
            justify-content: center;

            .profileContainer_avatarBlockLevel {
                height: 21px;

                display: flex;
                justify-content: center;

                border-radius: 30px;
                padding: 0px 9px;

                background: var(--level-badge-backgroun-color);

                display: flex;
                align-items: center;

                img {
                    width: 17px;
                    height: 17px;
                    margin-right: 8px;
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--main-font-color);
                    font-weight: 600;
                }
            }
        }
    }

    .profileContainer_profileNavigationBlock {
        margin-top: 22px;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .profileContainer_profileNavigationBlock_navItem {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 14px;
            color: var(--secondary-font-color);

            text-decoration: none;
            text-wrap: nowrap;

            margin-bottom: 8px;
            padding: 6px 12px;
            position: relative;

            transition: all .3s ease;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: var(--action-component-active-background-light-color);

                opacity: 0;
                transition: opacity .3s ease;
            }

            &.active {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 14px;
                background: var(--action-component-active-background-light-color);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &:hover::after,
            &.active::after {
                opacity: 1;
            }
        }
    }
}

.profileInfoBlock {
    padding: 8px 0 16px 0;

    >:not(:last-child) {
        margin-bottom: 16px;
    }

    .profileInfoBlock_input {
        >div {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 15px;
            line-height: 134%;
            color: var(--light-secondary-font-color);

            margin-bottom: 8px;
        }
    }

    .profileInfoBlock_checkBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 15px;
            line-height: 134%;
            color: var(--light-secondary-font-color);

        }
    }

    .profileInfoBlock_saveButton {
        @include blue-gradient-button;
    }
}

.profileBalanceBlock {
    margin-top: 16px;


    .profileBalanceBlock_balanceAccounts {
        > :not(:first-child):not(:last-child) {
            margin: 16px 0;
        }

        .profileBalanceBlock_balanceAccounts_divider {
            width: 100%;
            height: 1px;
            background: var(--divider_background-color);

            margin: 8px 0;
        }

        .profileBalanceBlock_balanceAccountsItem {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profileBalanceBlock_balanceAccountsItem_accountName {
                display: flex;
                align-items: center;

                span {
                    font-family: var(--font4);
                    font-weight: 600;
                    font-size: 14px;
                    color: var(--main-font-color);
                }

                img {
                    width: 27px;
                    height: 27px;
                    margin-right: 8px;
                }
            }

            .profileBalanceBlock_balanceAccountsItem_accountValue {
                font-family: var(--font-family);
                font-weight: 500;
                font-size: 14px;
                color: var(--main-font-color);
            }
        }
    }

    .profileBalanceBlock_withdrawButton {
        margin-top: 16px;
        @include blue-gradient-button;
    }


    .profileBalanceBlock_withdrawalMethods_title {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 15px;
        line-height: 134%;
        color: var(--white);

        margin: 16px 0 8px 0;
    }

    .profileBalanceBlock_withdrawalMethods {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        > :not(:last-child) {
            margin-right: 8px;
        }

        .profileBalanceBlock_withdrawalMethods_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                margin-top: 10px;
                text-align: center;
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 10px;
                color: var(--main-font-color);
            }
        }

        .profileBalanceBlock_withdrawalMethods_item.active {
            box-shadow: 2px 8px 18px 0 rgba(41, 55, 124, 0.04);
            background: var(--action-component-active-background-light-color);

            span {
                color: var(--card-secondary-bold-color);
            }
        }
    }
}

.profileBalancaHistoryBlock {
    margin-top: 16px;

    >:not(:last-child) {
        margin-bottom: 16px;
    }

    .profileBalancaHistoryBlock_title {
        display: flex;
        align-items: center;

        >div {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: var(--main-font-color);
        }
    }

    .profileBalancaHistoryBlock_transaction {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profileBalancaHistoryBlock_transactionLeft {
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;

                margin-right: 12px;
            }

            .profileBalancaHistoryBlock_transactionLeft_information {
                display: flex;
                flex-direction: column;

                >div {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: var(--main-font-color);
                }

                .profileBalancaHistoryBlock_transactionLeft_information_date {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        height: 20px;

                        margin-right: 3px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 145%;
                        color: var(--main-font-color);

                        opacity: 0.8;
                    }
                }
            }
        }

        .profileBalancaHistoryBlock_transactionRight {
            display: flex;
            flex-direction: column;

            .profileBalancaHistoryBlock_transactionRight_amount {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;

                    margin-right: 4px;
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: var(--main-font-color);
                }
            }

            .profileBalancaHistoryBlock_transactionRight_balance {
                margin-top: 4px;

                opacity: 0.8;

                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                color: var(--secondary-font-color);
            }
        }
    }
}


.profileGameHistoryBlock {
    margin-top: 16px;

    @media (min-width: 1018px) {
        max-width: 1170px;

        margin: 16px auto 0;
    }

    .gameListContainer_gameCard {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;

        box-sizing: border-box;

        background-color: var(--card-background-color);
        border-radius: 18px;
        box-shadow: 2px 8px 18px 0 rgba(41, 55, 124, 0.04);

        position: relative;

        .gameListContainer_gameCard_activeStatus {
            border-radius: 15px;
            padding: 1px 12px;
            box-sizing: border-box;
            height: 19px;

            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--active-status-badge-background-color);



            margin-bottom: 8px;

            >:not(:last-child) {
                margin-right: 4px;
            }

            >div {
                width: 5px;
                height: 5px;
                border-radius: 100%;
                animation: pulseAnimation 2s infinite ease-in-out;
                background-color: var(--active-status-circle-background-color);
            }

            >span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                line-height: 145%;
                color: var(--main-font-color);

                opacity: 0.8;
            }
        }

        @keyframes pulseAnimation {
            0% {
                box-shadow: 0 0 5px rgba(255, 255, 255, 0);
                opacity: 0.5;
            }

            50% {
                box-shadow: 0 0 10px var(--active-status-circle-background-color);
                opacity: 1;
            }

            100% {
                box-shadow: 0 0 5px rgba(255, 255, 255, 0);
                opacity: 0.5;
            }
        }

        .gameListContainer_gameCard_stats {
            display: flex;
            align-items: center;

            margin-bottom: 16px;

            >:not(:last-child) {
                margin-right: 8px;
            }

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 13px;
                    color: var(--secondary-font-color);
                }
            }
        }

        .comingSoonContainer {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #000000c2;

            span {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 22px;
                line-height: 132%;
                color: #fff;
            }
        }

        .gameListContainer_gameCard_info {
            display: flex;
            align-items: start;

            margin-bottom: 16px;

            .gameListContainer_gameCard_info_left {
                margin-right: 9px;

                img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;

                    @media (min-width: 1018px) {
                        width: 43px;
                        height: 43px;
                    }
                }
            }

            .gameListContainer_gameCard_info_right {

                width: 100%;

                .gameListContainer_gameCard_info_right_hedaer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;


                    >span {
                        border-radius: 6px;
                        padding: 0 4px;
                        box-sizing: border-box;
                        background: var(--card-badge-background);

                        font-family: var(--font-family);
                        font-weight: 500;
                        font-size: 11px;
                        color: var(--card-secondary-bold-color);
                    }

                    >div {
                        display: flex;
                        text-decoration: none;

                        a {
                            width: 24px;
                            height: 24px;

                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }


                        >:not(:last-child) {
                            margin-right: 9px;
                        }
                    }
                }

                .gameListContainer_gameCard_info_right_gameName {
                    margin-top: 4px;

                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 132%;
                    color: #fff;

                    @media (min-width: 1018px) {
                        font-size: 16px;
                    }
                }

                .gameListContainer_gameCard_info_right_statistics {
                    margin-top: 8px;

                    >div {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;

                            margin-right: 8px;
                        }

                        span {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 13px;
                            color: var(--light-secondary-font-color);
                        }
                    }
                }
            }
        }

        .gameListContainer_gameCard_action {
            width: 100%;

            display: flex;
            align-items: center;

            &.coef {
                display: flex;
                flex-direction: column;
            }

            .gameListContainer_gameCard_action_coef {
                display: grid;
                grid-template-columns: 2fr 1fr;
                align-items: center;
                justify-content: space-between;

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 14px;
                line-height: 132%;
                color: #fff;
                margin-right: 0px !important;
                margin-bottom: 8px;

            }

            >:not(:last-child) {
                margin-right: 16px;
            }

            >div {
                width: 100%;
            }
        }
    }


    >:not(:last-child) {
        margin-bottom: 16px;
    }

    .profileGameHistoryBlock_header {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .profileGameHistoryBlock_title {
            display: flex;
            align-items: center;

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: var(--main-font-color);

            .profileGameHistoryBlock_header_back {
                display: flex;
                align-items: center;
                margin-right: 10px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .profileGameHistoryBlock_balance {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            font-family: var(--font-family);
            font-weight: 700;
            font-size: 15px;
            line-height: 132%;
            color: #fff;

            span {
                margin-left: 4px;
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                }
            }


        }
    }

    .profileGameHistoryBlock_transactionContainer {
        position: relative;

        .profileGameHistoryBlock_transaction_gameInfo {
            margin-top: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            >:not(:last-child) {
                margin-right: 8px;
            }

            >div {
                display: flex;
                align-items: center;

                font-family: var(--font-family);
                font-weight: 400;
                font-size: 9px;
                line-height: 145%;
                color: var(--main-font-color);

                opacity: 0.8;

            }

            &.single {
                justify-content: flex-end;
                /* Когда первого блока нет */
            }
        }

        .profileGameHistoryBlock_transaction {
            display: flex;
            justify-content: space-between;

            .profileGameHistoryBlock_transactionLeft {
                display: flex;
                align-items: center;
                width: 70%;

                img {
                    width: 40px;
                    height: 40px;

                    margin-right: 12px;
                }

                .profileGameHistoryBlock_transactionLeft_information {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    width: 100%;


                    .profileGameHistoryBlock_transactionLeft_information_title_name {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .profileGameHistoryBlock_transactionLeft_information_title {
                        display: flex;
                        align-items: center;

                        >div {
                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 140%;
                            color: var(--main-font-color);

                            margin-right: 4px;
                        }

                        .profileGameHistoryBlock_transactionLeft_information_date {
                            display: flex;
                            align-items: center;

                            >img {
                                width: 15px;
                                height: 15px;

                                margin-right: 3px;
                            }

                            span {
                                font-family: var(--font-family);
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 145%;
                                color: var(--main-font-color);

                                opacity: 0.8;
                            }
                        }
                    }

                    .profileGameHistoryBlock_transactionLeft_information_sub {

                        display: flex;
                        align-items: center;

                        >:not(:last-child) {
                            margin-right: 4px;
                        }


                        span {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 140%;
                            color: var(--main-font-color);
                            opacity: 0.8;

                            &.selectedUp {
                                color: var(--green-);
                            }

                            &.selectedDown {
                                color: #f54570;
                            }

                        }

                        img {
                            width: 10px;
                            height: 10px;

                            margin-right: 2px;
                        }

                    }
                }
            }

            .profileGameHistoryBlock_transactionRight {
                display: flex;
                flex-direction: column;
                align-items: end;
                width: 30%;

                .profileGameHistoryBlock_transactionRight_amount {
                    display: flex;
                    align-items: center;

                    >img {
                        width: 20px;
                        height: 20px;

                        margin-right: 4px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 140%;
                        color: var(--main-font-color);
                    }
                }

                .profileGameHistoryBlock_transactionRight_balance {
                    margin-top: 4px;

                    opacity: 0.8;

                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 12px;
                    color: var(--secondary-font-color);
                }
            }
        }
    }



}

.profileStatsBlock {
    margin-top: 16px;

    .profileStatsBlock_totalEarningsCard {
        padding: 25px 16px;
        overflow: hidden;
        position: relative;

        box-shadow: 2px 8px 18px 0 rgba(41, 55, 124, 0.04);
        background: var(--action-component-active-background-light-color);

        .profileStatsBlock_totalEarningsCard_title {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: var(--card-secondary-bold-color);

            margin-bottom: 4px;

            position: relative;
            z-index: 100;
        }

        .profileStatsBlock_totalEarningsCard_subTitle {
            font-family: var(--font-family);
            font-weight: 800;
            font-size: 27px;
            color: var(--card-secondary-bold-color);

            position: relative;
            z-index: 100;
        }

        .profileStatsBlock_totalEarningsCard_pixVector1,
        .profileStatsBlock_totalEarningsCard_pixVector2 {
            width: 85px;
            height: 85px;

            position: absolute;

            z-index: 1;
        }

        .profileStatsBlock_totalEarningsCard_pixVector1 {
            bottom: 55px;
            right: 74px;
        }

        .profileStatsBlock_totalEarningsCard_pixVector2 {
            top: 16px;
            right: -18px;
        }

        .profileStatsBlock_totalEarningsCard_pix1,
        .profileStatsBlock_totalEarningsCard_pix2,
        .profileStatsBlock_totalEarningsCard_pix3 {
            position: absolute;
            z-index: 1;
        }

        .profileStatsBlock_totalEarningsCard_pix1 {
            width: 58px;
            height: 58px;

            top: 21px;
            right: 70px;
        }

        .profileStatsBlock_totalEarningsCard_pix2 {
            width: 37px;
            height: 37px;

            top: 75px;
            right: 31px;

            transform: rotate(-20deg);
        }

        .profileStatsBlock_totalEarningsCard_pix3 {
            width: 31px;
            height: 31px;

            top: 31px;
            right: 26px;

            transform: rotate(-20deg);

            filter: blur(1px);
        }
    }

    .profileStatsBlock_chart {
        margin-top: 16px;

        background: var(--card-background-color);
        display: flex;
        align-items: center;
        justify-content: center;

        .profileStatsBlock_chart_lables {
            font-family: var(--font-family) !important;
            font-weight: 400;
            font-size: 10px;
            line-height: 130%;
            fill: #b7b7b7 !important;
        }
    }

    .profileStatsBlock_earningsBreakdown {

        margin-top: 16px;

        .profileStatsBlock_earningsBreakdown_title {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 14px;
            color: var(--main-font-color);
        }

        .profileStatsBlock_earningsBreakdown_subTitle {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 12px;
            color: var(--main-font-color);

            margin-top: 8px;

            span {
                background: var(--main-colorize-font-color);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .profileStatsBlock_earningsBreakdown_values {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-top: 20px;

            border-radius: 68px;
            padding: 4px 16px;
            height: 47px;
            background: var(--button-secondary-background);

            >:not(:first-child):not(:last-child):nth-child(2n) {
                margin: 0 16px;
            }

            > :first-child {
                margin-right: 16px;
            }

            > :last-child {
                margin-left: 16px;
            }

            .profileStatsBlock_earningsBreakdown_valuesItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .profileStatsBlock_earningsBreakdown_valuesItemTitle {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 132%;
                    text-wrap: wrap;
                    text-align: center;
                    color: var(--light-secondary-font-color);
                }

                .profileStatsBlock_earningsBreakdown_valuesItemSubTitle {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 132%;
                    color: var(--main-font-color);

                    margin-top: 2px;
                }
            }
        }
    }

}

.profileNftsBlock {
    margin-top: 16px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 164px);
    gap: 16px;
    justify-content: center;

    .profileNftsBlock_nftCard {
        border-radius: 18px;
        box-sizing: border-box;
        padding: 8px;
        width: 164px;
        height: 202px;

        background: var(--card-background-color);

        >img {
            border-radius: 14px;
            width: 148px;
            height: 113px;
        }

        .profileNftsBlock_nftCard_title {
            margin-top: 8px;

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            line-height: 125%;
            letter-spacing: -0.03em;
            color: var(--main-font-color);
        }

        .profileNftsBlock_nftCard_subTitle {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 12px;
            line-height: 133%;
            color: var(--secondary-font-color);
        }

        .profileNftsBlock_nftCard_priceBlock {
            margin-top: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .profileNftsBlock_nftCard_priceBlock_ethereum {

                display: flex;
                align-items: center;

                img {
                    width: 7.5px;
                    height: 15px;
                    margin-right: 6px;
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 154%;
                    letter-spacing: -0.02em;
                    text-align: center;
                    color: var(--main-font-color);
                }
            }

            .profileNftsBlock_nftCard_priceBlock_code {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 13px;
                line-height: 100%;
                letter-spacing: 0.01em;
                text-align: center;

                color: var(--secondary-font-color);
            }
        }
    }
}

.profileReferralBlock {
    margin-top: 16px;

    >:not(:last-child) {
        margin-bottom: 16px;
    }

    .profileReferralBlock_referral {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profileReferralBlock_referralLeft {
            display: flex;
            align-items: center;

            .profileReferralBlock_referralLeft_avatar {
                width: 38px;
                height: 38px;

                display: flex;
                align-items: center;
                justify-content: center;

                margin-right: 12px;
                border-radius: 50%;

                background: var(--action-component-active-background-light-color);

                font-family: var(--second-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--card-secondary-bold-color);

            }

            .profileReferralBlock_referralLeft_information {
                display: flex;
                flex-direction: column;

                .profileReferralBlock_referralLeft_information_userName {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: var(--main-font-color);
                }

                .profileReferralBlock_referralLeft_information_date {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 145%;
                    color: var(--main-font-color);
                    opacity: 0.8;
                }
            }
        }

        .profileReferralBlock_referralRight {
            display: flex;
            flex-direction: column;

            .profileReferralBlock_referralRight_status {
                border-radius: 15px;
                padding: 1px 12px;
                box-sizing: border-box;
                width: 65px;
                height: 19px;

                display: flex;
                align-items: center;
                justify-content: center;

                >div {
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    background-color: var(--active-status-circle-background-color);
                    margin-right: 4px;
                }

                span {

                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 145%;
                    color: var(--main-font-color);

                    opacity: 0.8;
                }

            }

            .profileReferralBlock_referralRight_status.active {
                background: var(--active-status-badge-background-color);
            }

            .profileReferralBlock_referralRight_balance {
                margin-top: 4px;

                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;

                    margin-right: 4px;
                }

                span {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: var(--main-font-color);
                }
            }
        }
    }
}