.selectWrapper {
    position: relative;

    .select {
        border-radius: 16px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background: var(--text-input-backgroun-color);
        transition: background-color 0.2s ease;
        box-sizing: border-box;

        &.error {
            outline: 0.5px solid #e84266;
        }

        .select_left {
            span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 134%;
                color: var(--secondary-font-color);
            }
        }


        .select_left {
            display: flex;
            align-items: center;

            >img {
                margin-right: 8px;
            }
        }

        .select_right {
            display: flex;
            align-items: center;
        }


    }

    .options {
        border-radius: 16px;
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background-color: var(--text-input-backgroun-color);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        z-index: 10;

        .option {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.2s ease;

            img {
                margin-right: 8px;
            }

            span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 134%;
                color: var(--secondary-font-color);
            }
        }
    }
}