.resultModalContainer {
    .resultModalContainer_title {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 25px;
        line-height: 139%;
        text-align: center;

        img {
            width: 30px;
            height: 30px;
            margin-left: 10px;
        }

        &.red {
            color: #FE3D67;
        }

        &.green {
            color: lightgray;
        }

        &.gray {
            color: lightgray;
        }
    }

    .resultModalContainer_mainResult {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;

        img {
            width: 35px;
            height: 35px;

            margin-right: 8px;
        }

        .resultModalContainer_mainResult_main {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 30px;
            line-height: 132%;
            color: var(--main-font-color);
        }

        .resultModalContainer_mainResult_coeff {
            align-self: flex-end;
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 12px;
            line-height: 132%;
            color: var(--main-font-color);

            margin-left: 4px;
        }

    }

    .resultModalContainer_informationContainer {
        margin: 15px 0;
        display: flex;
        flex-direction: column;

        >:not(:last-child) {
            margin-bottom: 4px;
        }

        .resultModalContainer_informationContainer_item {
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: var(--font-family);
            font-weight: 400;
            font-size: 14px;
            line-height: 132%;
            color: var(--main-font-color);

            img {
                width: 15px;
                height: 15px;
                margin-left: 4px;
            }

        }
    }

    .resultModalContainer_buttons {
        margin-top: 16px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-wrap: nowrap;
        gap: 8px;

        .resultModalContainer_buttons_button,
        a {
            height: 42px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 20px;

            box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
            background: var(--confirm-button-background-color);

            font-family: var(--font-family);
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            text-align: center;
            color: var(--reverse-main-font-color);

            text-decoration: none;
        }
    }

    .resultModalContainer_buttons_button {
        height: 42px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;

        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--confirm-button-background-color);

        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        text-align: center;
        color: var(--reverse-main-font-color);

        text-decoration: none;
    }
}