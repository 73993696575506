.customPredictionContainer {
    padding: 16px;

    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }
    
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }

        .header_rightBlock {
            display: flex;
            align-items: center;

            >:not(:last-child) {
                margin-right: 8px;
            }
        }

        .header_rightBlock_element {
            display: flex;
            flex-direction: column;
            align-items: center;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_rightBlock_element_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff
            }

            .header_rightBlock_element_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }
        }

        margin-bottom: 16px;
    }

    .customPrediction_container {

        @media (min-width: 1018px) {
            max-width: 1140px;
            margin: 16px auto 16px;
        }

        .customPredictionContainer_card {
            display: flex;

            .customPredictionContainer_card_leftContent {
                margin-right: 9px;

                img {
                    width: 73px;
                    height: 73px;
                    object-fit: cover;
                }

            }

            .customPredictionContainer_card_rightContent {
                width: 100%;

                .customPredictionContainer_card_rightContent_header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    margin-bottom: 2px;

                    .customPredictionContainer_card_rightContent_header_title {
                        border-radius: 6px;
                        padding: 0px 4px;
                        height: 16px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        background: var(--card-badge-background);

                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 11px;
                        color: var(--reverse-main-font-color);
                    }

                    .customPredictionContainer_card_rightContent_header_buttons {
                        display: flex;
                        align-items: center;

                        a {
                            width: 24px;
                            height: 24px;
                        }

                        img {
                            width: 24px;
                            height: 24px;
                        }

                        >:not(:last-child) {
                            margin-right: 8px;
                        }

                    }
                }

                .customPredictionContainer_card_rightContent_title {
                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 132%;
                    color: var(--main-font-color);

                    margin-bottom: 8px;
                }

                .customPredictionContainer_card_rightContent_info {
                    display: flex;
                    align-items: center;

                    >:not(:last-child) {
                        margin-right: 8px;
                    }

                    >div {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                        }

                        span {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 13px;
                            color: var(--secondary-font-color);
                        }
                    }
                }
            }
        }

        .customPredictionContainer_purchaseBlock {
            margin-top: 16px;

            border-radius: 18px;
            padding: 16px;

            .purchaseBlock_info {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .tabControl {
                height: 48px;
                background-color: var(--action-component-background-light-color);
                margin-bottom: 12px;

                border-radius: 32px;
                padding: 3px;
                display: grid;
                grid-template-columns: 1fr 1fr;

                .tabControl_item {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border-radius: 32px;
                    font-family: var(--font-family);
                    padding: 4px 16px;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 132%;

                    text-align: center;
                    color: var(--secondary-font-color);
                }

                .tabControl_itemActive {
                    color: var(--reverse-main-font-color);
                    box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
                    background: var(--action-component-active-background-light-color);

                    font-weight: 600;
                    font-size: 14px;
                    line-height: 132%;

                    text-align: center;
                }
            }


            .customPredictionContainer_purchaseBlock_hint {
                display: flex;
                align-items: center;

                span {
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--secondary-font-color);

                    margin-right: 4px;

                    vertical-align: middle;
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .customPredictionContainer_purchaseBlock_actionButtons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 16px;

                margin-top: 8px;
            }

            .customPredictionContainer_purchaseBlock_moneyBlock {
                border-radius: 32px;
                padding: 3px 20px;
                height: 48px;

                background: var(--money-counter-background);

                margin-top: 16px;

                display: flex;
                align-items: center;

                justify-content: space-between;

                input {
                    outline: none;
                    background: none;
                    border: none;
                    -moz-appearance: textfield;

                    /* Убирает стрелки в Chrome */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 132%;
                    text-align: center;
                    color: var(--white-color);
                }
            }

            .customPredictionContainer_purchaseBlock_confirmButton {
                height: 42px;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 20px;
                margin-top: 16px;

                box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
                background: var(--confirm-button-background-color);

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 16px;
                line-height: 132%;
                text-align: center;
                color: var(--reverse-main-font-color);

                cursor: pointer;

                &.disabled {
                    opacity: 0.6;
                }

                >div{
                    display: flex;
                    align-items: center;
                    font-family: var(--font-family);

                    >div{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                        text-align: center;
                    }

                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }

            .customPredictionContainer_purchaseBlock_notification{
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                line-height: 132%;
                text-align: center;
                color: var(--secondary-font-color);
                margin-top: 4px;
            }

            .customPredictionContainer_purchaseBlock_statistics {
                display: flex;
                justify-content: space-around;
                align-items: center;

                margin-top: 20px;

                border-radius: 68px;
                box-sizing: border-box;
                padding: 4px 16px;
                height: 47px;
                background: var(--button-secondary-background);


                .customPredictionContainer_purchaseBlock_statisticsItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .customPredictionContainer_purchaseBlock_statisticsItemTitle {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 132%;
                        color: var(--light-secondary-font-color);

                        margin-bottom: 2px;
                    }

                    .customPredictionContainer_purchaseBlock_statisticsItemSubTitle {
                        font-family: var(--font-family);
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 132%;
                        color: var(--main-font-color);
                    }
                }
            }


            .divider {
                width: 1px;
                height: 20px;

                background: var(--divider-background);
            }
        }

        .customPredictionContainer_detailedInformationBlock {
            margin-top: 16px;

            >:not(:last-child) {
                margin-bottom: 16px;
            }

            .customPredictionContainer_detailedInformationBlock_description {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--main-font-color);

            }

            .customPredictionContainer_detailedInformationBlock_graph {
                background: var(--card-background-color);
                border-radius: 12px;
                padding: 16px;

                margin-bottom: 16px;

                span {
                    font-family: var(--font13);
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 132%;
                    text-align: right;
                    color: var(--main-font-color);

                    margin-bottom: 16px;
                }

                .customPredictionContainer_detailedInformationBlock_graph_addComment {
                    background: red;
                }

                .customPredictionContainer_detailedInformationBlock_graph_lables {
                    font-family: var(--font-family) !important;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 130%;
                    fill: var(--gray) !important;
                }
            }

            .customPredictionContainer_detailedInformationBlock_FundAllocationPie {
                background: var(--card-background-color);
                border-radius: 18px;
                padding: 16px;

                box-sizing: border-box;

                >:not(:last-child) {
                    margin-bottom: 8px;
                }

                .customPredictionContainer_detailedInformationBlock_FundAllocationPie_item {
                    display: grid;
                    grid-template-columns: 45px auto;
                    align-items: center;

                    .customPredictionContainer_detailedInformationBlock_FundAllocationPie_item_left {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--main-font-color);

                        margin-right: 7px;
                    }

                    .customPredictionContainer_detailedInformationBlock_FundAllocationPie_item_right {
                        font-family: var(--font-family);
                        font-weight: 300;
                        font-size: 12px;
                        letter-spacing: 0.03em;
                        color: var(--main-font-color);
                    }
                }



            }
        }
    }

}