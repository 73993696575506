.mainPageContainer {
    padding: 16px;
    position: relative;

    
    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }

        .header_rightBlock {
            display: flex;
            align-items: center;

            >:not(:last-child) {
                margin-right: 8px;
            }
        }

        .balance {
            cursor: pointer;
        }

        .header_rightBlock_element {
            display: flex;
            flex-direction: column;
            align-items: center;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_rightBlock_element_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff
            }

            .header_rightBlock_element_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }
        }

        margin-bottom: 16px;
    }

}

.mainBlockMobile {
    max-width: 1140px;
    margin: 16px auto 16px;
    display: none;

    .mainBlockMobile_main {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 1fr;

        >:not(:last-child) {
            margin-right: 20px;
        }
    }

    @media (min-width: 1018px) {
        display: flex;
        flex-direction: column;
    }

    .mainBlockMobile_PredictionContainer_card {
        display: flex;
        margin-bottom: 16px;

        .mainBlockMobile_PredictionContainer_card_leftContent {
            margin-right: 9px;

            img {
                width: 73px;
                height: 73px;
                object-fit: cover;
            }

        }

        .mainBlockMobile_PredictionContainer_card_rightContent {
            width: 100%;

            .mainBlockMobile_PredictionContainer_card_rightContent_header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 2px;

                .mainBlockMobile_PredictionContainer_card_rightContent_header_title {
                    border-radius: 6px;
                    padding: 0px 4px;
                    height: 16px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background: var(--card-badge-background);

                    font-family: var(--font-family);
                    font-weight: 600;
                    font-size: 11px;
                    color: var(--reverse-main-font-color);
                }

                .mainBlockMobile_PredictionContainer_card_rightContent_header_buttons {
                    display: flex;
                    align-items: center;

                    a {
                        width: 24px;
                        height: 24px;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    >:not(:last-child) {
                        margin-right: 8px;
                    }

                }
            }

            .mainBlockMobile_PredictionContainer_card_rightContent_title {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 20px;
                line-height: 132%;
                color: var(--main-font-color);

                margin-bottom: 8px;
            }

            .mainBlockMobile_PredictionContainer_card_rightContent_info {
                display: flex;
                align-items: center;

                >:not(:last-child) {
                    margin-right: 8px;
                }

                >div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 13px;
                        color: var(--secondary-font-color);
                    }
                }
            }
        }
    }

    .mainBlock_title {
        font-family: var(--font-family) !important;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: white;
    }

    .priceCharts {
        min-height: 250px;

        .priceCharts_lables {
            font-family: var(--font-family) !important;
            font-weight: 400;
            font-size: 10px;
            line-height: 130%;
            fill: var(--light-secondary-font-color) !important;
        }

        .priceCharts_header {
            display: flex;
            align-items: center;

            justify-content: space-between;

            .priceCharts_header_title {
                display: flex;
                align-items: start;

                .priceCharts_header_title_main {
                    font-family: var(--font13);
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 132%;
                    text-align: right;
                    color: var(--main-font-color);

                    margin-right: 4px;
                }

                .priceCharts_header_title_sub {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 10px;
                    color: var(--main-font-color);
                    padding: 4px 0;
                }
            }

            .priceCharts_header_buttons {
                display: flex;
                align-items: center;

                border-radius: 12px;
                padding: 3px 4px;
                background: var(--button-secondary-background);
                box-sizing: border-box;

                >span,
                >img {
                    cursor: pointer;
                }

                img {
                    width: 24px;
                    height: 24px;
                }

                >span {
                    padding: 4px;
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 132%;
                    color: var(--light-secondary-font-color);
                }

                >:not(:last-child):not(:last-child) {
                    padding-right: 8px;
                    border-right: 1px solid var(--divider-background);
                }

                >:not(:first-child) {
                    padding-left: 8px;
                }



            }
        }

        .priceCharts_swapButton {
            display: flex;
            align-items: center;

            img {
                margin-right: 4px;
            }

            span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                color: var(--secondary-font-color);
            }
        }
    }
}

.mainBlock {
    width: 100%;
    margin-top: 16px;

    @media (min-width: 1018px) {
        display: none;
    }

    .mainBlock_title {
        font-family: var(--font-family) !important;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: white;
    }

    .priceCharts {
        min-height: 250px;

        .priceCharts_lables {
            font-family: var(--font-family) !important;
            font-weight: 400;
            font-size: 10px;
            line-height: 130%;
            fill: var(--light-secondary-font-color) !important;
        }

        .priceCharts_header {
            display: flex;
            align-items: center;

            justify-content: space-between;

            .priceCharts_header_title {
                display: flex;
                align-items: start;

                .priceCharts_header_title_main {
                    font-family: var(--font13);
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 132%;
                    text-align: right;
                    color: var(--main-font-color);

                    margin-right: 4px;
                }

                .priceCharts_header_title_sub {
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 10px;
                    color: var(--main-font-color);
                    padding: 4px 0;
                }
            }

            .priceCharts_header_buttons {
                display: flex;
                align-items: center;

                border-radius: 12px;
                padding: 3px 4px;
                background: var(--button-secondary-background);
                box-sizing: border-box;

                >span,
                >img {
                    cursor: pointer;
                }

                img {
                    width: 24px;
                    height: 24px;
                }

                >span {
                    padding: 4px;
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 132%;
                    color: var(--light-secondary-font-color);
                }

                >:not(:last-child):not(:last-child) {
                    padding-right: 8px;
                    border-right: 1px solid var(--divider-background);
                }

                >:not(:first-child) {
                    padding-left: 8px;
                }



            }
        }

        .priceCharts_swapButton {
            display: flex;
            align-items: center;

            img {
                margin-right: 4px;
            }

            span {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 12px;
                color: var(--secondary-font-color);
            }
        }
    }
}

.sliderContainer {

    @media (min-width: 1018px) {
        display: none;
    }
}

.tabControl {
    height: 48px;
    background-color: var(--action-component-background-light-color);
    margin-bottom: 12px;

    border-radius: 32px;
    padding: 3px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .tabControl_item {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 32px;
        font-family: var(--font-family);
        padding: 4px 16px;

        font-weight: 400;
        font-size: 14px;
        line-height: 132%;

        text-align: center;
        color: var(--secondary-font-color);
    }

    .tabControl_itemActive {
        color: var(--reverse-main-font-color);
        box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
        background: var(--action-component-active-background-light-color);

        font-weight: 600;
        font-size: 14px;
        line-height: 132%;

        text-align: center;
    }
}



.familiesStats_values {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 16px 0px;
    border-bottom: 1px solid #f1f4f6;
    margin-bottom: 16px;

    .familiesStats_value {
        display: flex;
        align-items: center;

        img {
            width: 34px;
            height: 34px;
            margin-right: 8px;
        }

        .familiesStats_text {
            .familiesStats_title {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                line-height: 132%;
                color: var(--secondary-font-color);
            }

            .familiesStats_subTitle {
                font-family: var(--font13);
                font-weight: 400;
                font-size: 24px;
                line-height: 132%;
                color: #fff;
            }
        }
    }
}

.divider {
    width: 1px;
    height: 20px;

    background: var(--divider-background);
}

.familiesStats_rating {
    .familiesStats_familyInfo {
        display: flex;
        justify-content: space-between;

        .familiesStats_familyMembers {
            display: flex;
            align-items: center;
            position: relative;

            :first-child {
                margin-left: 0px;
            }

            img {
                width: 20px;
                height: 20px;

                border-radius: 50%;
                margin-left: -8px;
                object-fit: cover;
            }

            .familiesStats_extraMember {
                width: 20px;
                height: 20px;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 50%;
                background-color: var(--family-member-count-badge-background);
                margin-left: -8px;

                font-family: var(--font-family);
                font-weight: 400;
                font-size: 11px;
                line-height: 132%;
                color: var(--family-member-count-badge-font-color);
            }

            .familiesStats_familyName {
                font-family: var(--font-family);
                font-weight: 500;
                font-size: 18px;
                line-height: 132%;
                color: var(--main-font-color);

                margin-left: 8px;
            }
        }
    }

    .familiesStats_statistics {
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-top: 20px;

        border-radius: 68px;
        padding: 4px 16px;
        height: 47px;
        background: var(--button-secondary-background);
        box-sizing: border-box;

        .familiesStats_statisticsItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .familiesStats_statisticsItemTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 10px;
                line-height: 132%;
                color: var(--light-secondary-font-color);

                margin-bottom: 2px;
            }

            .familiesStats_statisticsItemSubTitle {
                font-family: var(--font-family);
                font-weight: 500;
                font-size: 12px;
                line-height: 132%;
                color: var(--main-font-color);
            }
        }
    }
}

.commentBlock {
    margin: 16px 0;
}