.container {
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;

    &.small {
        padding: 4px 10px;

        .title {
            font-size: 14px;
            text-align: center;
        }

        .subTitle {
            font-size: 12px;
        }
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 139%;
        text-align: right;

        font-family: var(--font-family);

        margin-right: 4px;
        text-align: center;
    }

    .title.red {
        color: #f54570;
    }

    .title.green {
        color: var(--success-button-font-color);
    }

    .subTitle {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 14px;
        line-height: 139%;
        text-align: right;

        margin-right: 8px;

    }

    .subTitle.red {
        color: #f54570;
    }

    .subTitle.green {
        color: var(--success-button-font-color);
    }
}

.container.red {
    background: rgba(224, 59, 69, 0.09);
}

.container.red.disabled {
    opacity: 0.5;
}

.container.red.selected {
    border: 1px solid rgba(224, 59, 69, 0.7);
    box-shadow: 0px 0px 12px -2px rgba(224, 59, 69, 0.7);
}

.container.green {
    background-color: var(--success-button-background);
}

.container.green.disabled {
    opacity: 0.5;
}

.container.green.selected {
    border: 1px solid rgb(0, 255, 0, 0.7);
    box-shadow: 0px 0px 12px -2px rgb(0, 255, 0, 0.7);
}

.container.flat {
    background: rgba(40, 175, 94, 0.16);

    .title,
    .subTitle {
        color: #25b35e;
    }
}

.container.flat.disabled {
    opacity: 0.5;
}

.container.flat.selected {
    border: 1px solid rgb(0, 255, 0, 0.7);
    box-shadow: 0px 0px 12px -2px rgb(0, 255, 0, 0.7);
}