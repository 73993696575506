.somePredictionContainer {
    padding: 16px;
    @media (min-width: 1018px) {
        max-width: 1140px;
        margin: 16px auto 16px;
    }

    
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            cursor: pointer;

            img {
                width: 62px;
                height: 55px;
            }
        }

        .header_rightBlock {
            display: flex;
            align-items: center;

            >:not(:last-child) {
                margin-right: 8px;
            }
        }

        .header_rightBlock_element {
            display: flex;
            flex-direction: column;
            align-items: center;

            border-radius: 8px;
            padding: 4px 7px;

            box-sizing: border-box;
            background: var(--mini-card-background-color);

            >div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .header_rightBlock_element_title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 15px;
                line-height: 132%;
                color: #fff
            }

            .header_rightBlock_element_subTitle {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 15px;
                line-height: 132%;
                text-align: right;
                color: var(--light-secondary-font-color);
            }
        }

        margin-bottom: 16px;
    }

    .someVariants_conatiner {
        @media (min-width: 1018px) {
            max-width: 1140px;
            margin: 16px auto 16px;
        }

        .somePredictionContainer_card {
            display: flex;
            flex-direction: column;


            .somePredictionContainer_card_leftContent {
                margin-right: 9px;

                img {
                    width: 73px;
                    height: 73px;
                    object-fit: cover;
                }

            }

            .somePredictionContainer_card_container {
                display: flex;

                .somePredictionContainer_card_container_image {
                    margin-right: 8px;

                    img {
                        width: 73px;
                        height: 73px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .somePredictionContainer_card_container_right {
                    width: 100%;

                    .somePredictionContainer_card_container_right_header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        margin-bottom: 2px;

                        .somePredictionContainer_card_container_right_header_title {
                            border-radius: 6px;
                            padding: 0px 4px;
                            height: 16px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background: var(--card-badge-background);

                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 11px;
                            color: var(--reverse-main-font-color);
                        }

                        .somePredictionContainer_card_container_right_header_buttons {
                            display: flex;
                            align-items: center;

                            a {
                                width: 24px;
                                height: 24px;
                            }

                            img {
                                width: 24px;
                                height: 24px;
                            }

                            >:not(:last-child) {
                                margin-right: 8px;
                            }

                        }
                    }

                    .somePredictionContainer_card_container_right_title {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 132%;
                        color: var(--main-font-color);

                        margin-bottom: 8px;
                    }
                }

            }

            .somePredictionContainer_card_container_downInfo {
                display: flex;
                align-items: center;
                margin-top: 8px;

                >:not(:last-child) {
                    margin-right: 8px;
                }

                >div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    span {
                        font-family: var(--font-family);
                        font-weight: 400;
                        font-size: 13px;
                        color: var(--secondary-font-color);
                    }
                }
            }
        }

        .somePredictionContainer_variantBloack {
            margin-top: 16px;
            display: flex;
            flex-direction: column;

            >:not(:last-child) {
                margin-bottom: 16px;
            }

            .somePredictionContainer_variantBloack_moneyBlock {
                border-radius: 32px;
                padding: 3px 20px;
                height: 48px;

                background: var(--money-counter-background);

                margin-top: 16px;

                display: flex;
                align-items: center;

                justify-content: space-between;

                input {
                    outline: none;
                    background: none;
                    border: none;
                    -moz-appearance: textfield;

                    /* Убирает стрелки в Chrome */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    font-family: var(--font-family);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 132%;
                    text-align: center;
                    color: var(--white-color);
                }
            }

            .somePredictionContainer_variantBloack_item {
                display: flex;
                flex-direction: column;

                .somePredictionContainer_variantBloack_buttons_button {
                    cursor: pointer;
                    display: flex;
                    border-radius: 8px;
                    padding: 10px 23px;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(160, 160, 160);



                    .somePredictionContainer_variantBloack_buttons_button_title {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 139%;
                        text-align: right;
                        color: var(--main-font-color);
                        font-family: var(--font-family);
                        opacity: 0.6;

                        margin-right: 4px;
                    }

                    .somePredictionContainer_variantBloack_buttons_button_subTitle {
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 139%;
                        text-align: right;

                        margin-right: 8px;
                        color: var(--main-font-color);
                        font-family: var(--font-family);
                        opacity: 0.6;
                    }

                    &.selected {
                        opacity: 1;
                        background-color: var(--success-button-background);
                        border: 1px solid rgb(0, 255, 0, 0.7);
                        box-shadow: 0px 0px 12px -2px rgb(0, 255, 0, 0.7);

                        .somePredictionContainer_variantBloack_buttons_button_title {
                            color: var(--success-button-font-color);
                            opacity: 1;
                        }

                        .somePredictionContainer_variantBloack_buttons_button_subTitle {
                            color: var(--success-button-font-color);
                            opacity: 1;
                        }
                    }

                    &.disabled {
                        opacity: 0.5;
                    }
                }

                .somePredictionContainer_variantBloack_info {
                    display: flex;
                    justify-content: space-between;

                    .somePredictionContainer_variantBloack_info_block {
                        display: flex;

                        img {
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            object-fit: cover;
                        }
                    }

                    .somePredictionContainer_variantBloack_info_title {

                        >div {
                            font-family: var(--font-family);
                            font-weight: 600;
                            font-size: 16px;
                            color: var(--main-font-color);
                        }

                        >span {
                            font-family: var(--font-family);
                            font-weight: 400;
                            font-size: 13px;
                            color: var(--secondary-font-color);
                        }
                    }

                    .somePredictionContainer_variantBloack_rightBlock {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 30px;
                        color: var(--main-font-color);
                    }
                }

                .somePredictionContainer_variantBloack_buttons {
                    margin-top: 10px;
                    // display: grid;
                    // grid-template-columns: 1fr 1fr;
                    // gap: 16px;
                }
            }

            .somePredictionContainer_variantBloack_buttons_confirmButton {
                height: 42px;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 20px;
                margin-top: 16px;

                box-shadow: 0 20px 40px 0 rgba(55, 62, 125, 0.1);
                background: var(--confirm-button-background-color);

                font-family: var(--font-family);
                font-weight: 600;
                font-size: 16px;
                line-height: 132%;
                text-align: center;
                color: var(--reverse-main-font-color);

                cursor: pointer;

                &.disabled {
                    opacity: 0.6;
                }

                >div{
                    display: flex;
                    align-items: center;
                    font-family: var(--font-family);

                    >div{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                        text-align: center;
                    }

                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
          
        }

        .somePredictionContainer_detailedInformationBlock {
            margin-top: 16px;

            >:not(:last-child) {
                margin-bottom: 16px;
            }

            .somePredictionContainer_detailedInformationBlock_description {
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--main-font-color);

            }

            .somePredictionContainer_detailedInformationBlock_graph {
                background: var(--card-background-color);
                border-radius: 12px;
                padding: 16px;

                margin-bottom: 16px;

                span {
                    font-family: var(--font13);
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 132%;
                    text-align: right;
                    color: var(--main-font-color);

                    margin-bottom: 16px;
                }

                .somePredictionContainer_detailedInformationBlock_graph_addComment {
                    background: red;
                }

                .somePredictionContainer_detailedInformationBlock_graph_lables {
                    font-family: var(--font-family) !important;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 130%;
                    fill: var(--gray) !important;
                }
            }

            .somePredictionContainer_detailedInformationBlock_FundAllocationPie {
                background: var(--card-background-color);
                border-radius: 18px;
                padding: 16px;

                box-sizing: border-box;

                >:not(:last-child) {
                    margin-bottom: 8px;
                }

                .somePredictionContainer_detailedInformationBlock_FundAllocationPie_item {
                    display: grid;
                    grid-template-columns: 45px auto;
                    align-items: center;

                    .somePredictionContainer_detailedInformationBlock_FundAllocationPie_item_left {
                        font-family: var(--font-family);
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--main-font-color);

                        margin-right: 7px;
                    }

                    .somePredictionContainer_detailedInformationBlock_FundAllocationPie_item_right {
                        font-family: var(--font-family);
                        font-weight: 300;
                        font-size: 12px;
                        letter-spacing: 0.03em;
                        color: var(--main-font-color);
                    }
                }



            }
        }
    }

}