.fundsAllocationContainer {
    padding: 38px 16px;

    .fundsAllocationContainer_chart {
        border-radius: 12px;
        padding: 16px;
        height: 259px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--card-background-color);
    }

    .fundsAllocationContainer_chartValues {
        margin-top: 16px;

        >:not(:last-child) {
            margin-bottom: 16px;
        }

        .fundsAllocationContainer_chartValue {
            padding: 10px 16px;
            background-color: var(--card-background-color);
            border-radius: 12px;

            display: flex;
            align-items: center;

            .fundsAllocationContainer_chartValue_persent {
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 14px;
                color: var(--main-font-color);

                margin-right: 8px;
            }

            .fundsAllocationContainer_chartValue_description {
                font-family: var(--font-family);
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 0.03em;
                text-align: center;
                color: var(--main-font-color);
            }
        }
    }
}