.app {
    min-height: calc(100vh - 100px);
    overflow-x: hidden;
    padding-bottom: 100px;
    background-color: var(--background-color);

    background-image: var(--background-image);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media(min-width: 1024px) {
        padding-bottom: 16px;
        padding-left: 0px;
        min-height: 100vh;
    }

    .bottomMenu {
        @media(min-width: 1024px) {
            display: none;
        }
    }
}